<template>
  <div class="uk-card uk-card-default uk-card-body lesson-wrapper">
    <a :href="`/courses/${info.slug_name}`">
      <div class="flex-wrapper">
        <div class="img-wrapper">
          <div class="ratio">
            <img uk-img width="600" height="600" :data-src="image">
          </div>
        </div>
        <div class="content-wrapper course-list-content-wrapper">
          <!-- <div class="badge" :class="{ 'active': course_status !== 'FUNDING' }">
            <span v-if="course_status === 'FUNDING'">募資中</span>
            <span v-else-if="course_status === 'FUNDED'">募資結束</span>
            <span v-else-if="course_status === 'PREPARE'">準備中</span>
            <span v-else-if="course_status === 'CANCELED'">取消</span>
            <span v-else-if="course_status === 'ON_SALE'">上課囉</span>
          </div> -->
          <div class="title course-list-title"><h3>{{ title }}</h3></div>
          <div v-if="course_status==='FUNDING'">
            <div class="content-row">募資時間 {{ expire_in }}</div>
            <div class="content-row funding-row" style="display: flex;
    justify-content: space-between;">
              <span>已募資 {{ funding_percent }}%</span>
              <span class="people-img-wrapper"><div class="people-img"></div>{{ this.info.enrolled_user_count }}</span>
            </div>
            <div class="content-row">
              <div class="progress-bar">
                <span :style="{ width: progressbar_width + '%' }"></span>
              </div>
            </div>
          </div>
          <div v-if="course_status==='FUNDED'">
          </div>
          <div class="price-wrapper">
            <div class="subtitle">
              <span v-if="course_status==='FUNDING'">預購價</span>
            </div>
            <div class="price">
              <div class="sale-price" :class="{ 'active': course_status !== 'FUNDING' }">
                <span v-if="course_status === 'FUNDING'">NT$ {{ formatNumber(this.info.funding_price) }}</span>
                <span v-else-if="course_status === 'FUNDED'">NT$ {{ formatNumber(this.info.sale_price) }}</span>
                <span v-else-if="course_status === 'PREPARE'">NT$ {{ formatNumber(this.info.sale_price) }}</span>
                <span v-else-if="course_status === 'CANCELED'">-</span>
                <span v-else-if="course_status === 'ON_SALE'">NT$ {{ formatNumber(this.info.sale_price) }}</span>
              </div>
              <!-- <div v-if="course_status!=='ON_SALE'" class="orig-price">NT$ {{ formatNumber(this.info.sale_price) }}</div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="creator-wrapper flex-wrapper">
        <div class="avatar">
          <img uk-img width="60" height="60" :data-src="ownerAvatar" alt="">
        </div>
        <div class="title">{{ creator }}</div>
        <div class="subtitle">{{ organization }}</div>
        <!-- <div class="badge" :class="{ 'active': course_status !== 'FUNDING' }">
          <span v-if="course_status === 'FUNDING'">募資中</span>
          <span v-else-if="course_status === 'FUNDED'">募資結束</span>
          <span v-else-if="course_status === 'PREPARE'">準備中</span>
          <span v-else-if="course_status === 'CANCELED'">取消</span>
          <span v-else-if="course_status === 'ON_SALE'">上課囉</span>
        </div> -->
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: 'AppLesson',
  props: ['info'],
  computed: {
    isHK() {
      return window.location.host == 'hk.bemaster.tw'; 
    },
    title() {
      return this.info && this.info.title ? this.info.title : ''
    },
    course_status() {
      return this.info && this.info.course_status ? this.info.course_status : ''
    },
    image() {
      return this.info && this.info.course_img ? this.info.course_img : ''
    },
    creator() {
      return this.info && this.info.owner && this.info.owner.username ? this.info.owner.username : 'UNKNOWN'
    },
    organization() {
      let organization =  this.info && this.info.owner && this.info.owner.company_name ? this.info.owner.company_name : 'UNKNOWN'
      if (organization == '愛禮物創意設計' && this.isHK) {
        organization = '港商愛禮物創意設計'
      }
      return organization
    },
    ownerAvatar() {
      return this.info && this.info.owner && this.info.owner.profile_img ? this.info.owner.profile_img : ''
    },
    expire_in() {
      if (this.info.funding_end_at) {
        let now = new Date().getTime()
        let end_at =  new Date(this.info.funding_end_at).getTime()
        var leftsecond = parseInt((end_at - now)/1000)
        if (leftsecond < 0) {
          return "已結束"
        } else {
          let d = parseInt(leftsecond/3600/24)
          let h = parseInt((leftsecond/3600)%24)
          if (d > 0) {
            return `剩 ${d} 天`
          } else if (h > 0) {
            return `剩 ${h} 小時`
          } else {
            return `低於 1 小時`
          }
        }        
      }
      return ""
    },
    progressbar_width () {
      return this.funding_percent > 100 ? 100: this.funding_percent
    },
    funding_percent() {
      if (this.info.funding_target_enrolled_amount) {
        let target = parseInt(this.info.funding_target_enrolled_amount)
        let current = parseInt(this.info.enrolled_user_count)
        let result = Math.round(current/target*100)
        return result;// > 100 ? 100 : result
      } else {
        return 0
      }
    }
  },
  methods: {
    formatNumber(num) {
      return num ? num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'): '0'
    }
  },
  created() {
  }
}
</script>

<style scoped lang="scss">

</style>
