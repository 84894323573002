import Vue from 'vue'

// css
import '../../node_modules/uikit/dist/css/uikit.min.css'
import '../sass/index.scss'

import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';

import AppCourseList from '@/components/AppCourseList'
import AppProfile from '@/components/AppProfile.vue'
import AppLesson from '@/components/AppLesson'

UIkit.use(Icons);

Vue.config.productionTip = false

import mixin from '../utils/mixin'

new Vue({
  el: '#app',
  mixins: [mixin],
  components: { AppProfile, AppCourseList, AppLesson },
  data() {
    return {
    }
  },
  methods: {
    
  },
  created() {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 0)
  }
})
