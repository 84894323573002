<template>
  <div class="uk-grid-match uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-4@m uk-text-center uk-grid-small" uk-grid>
    <div v-for="c in courses" :key="c.id">
      <app-lesson :info="c"></app-lesson>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import UIkit from 'uikit';
import AppLesson from './AppLesson';

const getCookie = (name) => {
  var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
  if (arr = document.cookie.match(reg))
    return (arr[2]);
  else
    return null;
}

export default {
  name: 'AppCourseList',
  props: ['courses'],
  components: { AppLesson },
  data() {
    return {

    }
  },
  computed: {
    
  },
  methods: {

  },
  created() {
    
  }
}
</script>

<style scoped lang="scss">

</style>



