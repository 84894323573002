/* eslint-disable */
import UIkit from 'uikit';
import axios from 'axios';

const getCookie = (name) => {
  var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
  if (arr = document.cookie.match(reg))
    return (arr[2]);
  else
    return null;
}

export default {
  data() {
    return {
      loading: false,
      login: {
        email: "",
        password: ""
      },
      loginError: {
        email: false,
        password: false
      },
      signup: {
        username: "",
        email: "",
        password1: "",
        password2: ""
      },
      signupError: {
        username: false,
        email: false,
        password1: false,
        password2: false,
        unique: false
      },
      reset: {
        password1: "",
        password2: ""
      },
      resetError: {
        password1: false,
        password2: false
      },
      resetPasswordError: {
        email: false,
        notExist: false
      },
      resetEmail: "",
      isForgot: true,
    }
  },
  methods: {
    triggerPixel(eventName) {
      fbq('track', eventName);
    },
    resetSubmit(e) {
      this.resetError = {
        password1: false,
        password2: false
      }

      let passRule = /^(?=.*\d)(?=.*[a-z])(?=.*[0-9])[a-zA-Z0-9!@#$%^&*.]{6,}$/
      if (!passRule.test(this.reset.password1)) {
        this.resetError.password1 = true     
      }
      if (this.reset.password1.length === 0) {
        this.resetError.password1 = true
      }
      if (this.reset.password1 !== this.reset.password2) {
        this.resetError.password2 = true
      }
      if (this.resetError.password1 || this.resetError.password2) {
        e.preventDefault()
      }
    },
    toRegister() {
      if (this.loading) {
        return
      }

      UIkit.modal('#modal-login').hide()
      UIkit.modal('#modal-register').show()
      // We use url from elements in login.html
      const fb_login_path = document.getElementById('social-fb-login').href
      const google_login_path = document.getElementById('social-google-login').href

      document.getElementById('social-fb-register').href = fb_login_path
      document.getElementById('social-google-register').href = google_login_path
      
    },
    toLogin(nextPath=null) {
      if (this.loading) {
        return
      }
      UIkit.modal('#modal-forgot').hide()
      UIkit.modal('#modal-register').hide()
      UIkit.modal('#modal-login').show()

      if (nextPath) {
        document.getElementById('social-fb-login').href = '/accounts/facebook/login/?next=' + nextPath
        document.getElementById('social-google-login').href = '/accounts/google/login/?next=' + nextPath
      } else {
        document.getElementById('social-fb-login').href = '/accounts/facebook/login/'
        document.getElementById('social-google-login').href = '/accounts/google/login/'
      }
    },
    onLoginSubmit() {
      if (this.loading) {
        return
      }
      this.loginError.email = false;
      this.loginError.password = false;
      let emailRule = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/
      if (this.login.email.search(emailRule)!== -1) {
        this.loginError.email = false
      } else {
        this.loginError.email = true     
      }
      this.loading = true
      axios({
        method: 'post',
        url: '/api/login',
        headers: {
          'X-CSRFToken': getCookie('csrftoken')
        },
        data: {
          email: this.login.email,
          password: this.login.password
        }
      })
      .then((res) => {
        let loginRedirect = localStorage.getItem('loginRedirect')
        if (loginRedirect) {
          localStorage.removeItem('loginRedirect')
          window.location.pathname = loginRedirect
        } else {
          window.location.reload(true);
        }      
      }).catch((err) => {
        this.loginError.email = true;
        this.loginError.password = true;
        this.loading = false
      })
    },
    toLogout() {
      this.$refs.logout.submit()
    },
    onSignUpSubmit() {
      if (this.signup.username.length === 0 || this.signup.username.length > 20) {
        this.signupError.username = true
      } else {
        this.signupError.username = false
      }
      let emailRule = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/
      if (this.signup.email.search(emailRule)!== -1) {
        this.signupError.email = false
      } else {
        this.signupError.email = true     
      }
      let passRule = /^(?=.*\d)(?=.*[a-z])(?=.*[0-9])[a-zA-Z0-9!@#$%^&*.]{6,}$/
      if (passRule.test(this.signup.password1)) {
        this.signupError.password1 = false
      } else {
        this.signupError.password1 = true     
      }
      if (this.signup.password1 === this.signup.password2) {
        this.signupError.password2 = false
      } else {
        this.signupError.password2 = true     
      }
      if (this.signupError.username || this.signupError.email || this.signupError.password1 || this.signupError.password2) {
        return
      } else {
        this.triggerPixel('Lead')
        this.signupError.unique = false
        this.loading = true
        axios({
          method: 'post',
          url: '/api/signup',
          headers: {
            'X-CSRFToken': getCookie('csrftoken')
          },
          data: {
            email: this.signup.email,
            password1: this.signup.password1,
            password2: this.signup.password2,
            username: this.signup.username
          }
        })
        .then((res) => {
          UIkit.modal('#modal-register').hide()
          let loginRedirect = localStorage.getItem('loginRedirect')
          if (loginRedirect) {
            localStorage.removeItem('loginRedirect')
            window.location.pathname = loginRedirect
          } else {
            UIkit.modal('#modal-register-success').show()
          }
        }).catch((err) => {
          if (err.response && err.response.data && err.response.data.indexOf('authentication_account_email_key') > -1) {
            this.signupError.unique = true
          }
          this.loading = false
        })
      }
    },
    toResetPassword() {
      this.isForgot = true
      UIkit.modal('#modal-login').hide()
      UIkit.modal('#modal-forgot').show()
    },
    onResetAgain() {
      let emailRule = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/
      if (this.resetEmail.search(emailRule)!== -1) {
        this.resetPasswordError.email = false
      } else {
        this.resetPasswordError.email = true  
        return   
      }
      this.resetPasswordError.notExist = false
      this.loading = true
      axios({
        method: 'post',
        url: '/api/send-reset-link',
        headers: {
          'X-CSRFToken': getCookie('csrftoken')
        },
        data: {
          email: this.resetEmail,
        }
      })
      .then((res) => {
        this.loading = false
      }).catch((err) => {
        if (err.response && err.response.data && err.response.data.indexOf('not exist') > -1) {
          this.resetPasswordError.notExist = true
        }
        this.loading = false
      })
    },
    onResetPassword() {
      let emailRule = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/
      if (this.resetEmail.search(emailRule)!== -1) {
        this.resetPasswordError.email = false
      } else {
        this.resetPasswordError.email = true  
        return   
      }
      this.resetPasswordError.notExist = false
      this.loading = true
      axios({
        method: 'post',
        url: '/api/send-reset-link',
        headers: {
          'X-CSRFToken': getCookie('csrftoken')
        },
        data: {
          email: this.resetEmail,
        }
      })
      .then((res) => {
        UIkit.modal('#modal-forgot').hide()
        UIkit.modal('#modal-forgot-success').show()
        this.loading = false
      }).catch((err) => {
        if (err.response && err.response.data && err.response.data.indexOf('not exist') > -1) {
          this.resetPasswordError.notExist = true
        }
        this.loading = false
      })
    }
  },
  created() {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 0)
    let path = window.location.pathname
    if (path.toLowerCase().indexOf('profile') === -1) {
      let result = localStorage.getItem('saveProfile')
      if (result) {
        localStorage.removeItem('saveProfile')
      }
    }
  }
}