<template>
  <div class="profile-content-wrapper">
    <div class="row">
      <span class="member-type primary">一般會員</span>
      <span>{{ user.username }}</span>
    </div>
    <div class="row">修改會員資料</div>
    <div class="row">
      <div class="content-body">
        <div class="uk-child-width-1-2@m" uk-grid>
          <div>
            <div class="item-title">
              顯示名稱
            </div>
            <input v-model="username" type="text" class="input-elm"  :class="{ 'error': profileError.username }" placeholder="請填入使用者名稱">
            <span class="tip" :class="{ 'error': profileError.username }">
              <span>名稱不能空白</span>
            </span>
          </div>
          <div>
            <div class="item-title">
              註冊信箱
            </div>
            <input v-model="email" type="email" class="input-elm" :class="{ 'error': profileError.email }" placeholder="請填入電子信箱">
            <span class="tip" :class="{ 'error': profileError.email }">
              <span>信箱格式錯誤</span>
            </span>
          </div>
          <div>
            <div class="item-title">
              密碼
            </div>
            <div class="reset-password">
              <a v-show="!loading" @click.prevent="onResetPassword">設置新密碼</a>
              <div v-show="loading" uk-spinner></div>
            </div>
          </div>
          <div>
            <div class="item-title">
              聯絡電話（僅用於訂單發生問題時聯絡用）
            </div>
            <input v-model="phone_num" type="tel" class="input-elm" :class="{ 'error': profileError.phone_num }" placeholder="請填入手機號碼">
            <span class="tip" :class="{ 'error': profileError.phone_num }">
              <span>聯絡電話格式錯誤</span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="uk-child-width-1-2@m uk-grid-match" uk-grid>
        <div>
          <div class="item-title">
             帳戶綁定 
          </div>
          <div class="social-btn">
            <a :href="login_types.indexOf('facebook') === -1 ? fb_connect : '#'">
              <button class="fb">
                <img src="https://pics.seeds.com.tw/fb-login.png" alt="">
              </button>
            </a>
            <span v-if="login_types.indexOf('facebook') === -1">選擇綁定帳戶</span>
            <span class="social-connected" v-if="login_types.indexOf('facebook') > -1">已綁定</span>
            
          </div>
          <div class="social-btn">
            <a :href="login_types.indexOf('google') === -1 ? g_connect : '#'">
              <button class="google">
                <img src="https://pics.seeds.com.tw/google-login.png" alt="">
              </button>
            </a>
            <span v-if="login_types.indexOf('google') === -1">選擇綁定帳戶</span>
            <span class="social-connected" v-if="login_types.indexOf('google') > -1">已綁定</span>
          </div>
          
        </div>
        <div>
          <div class="item-title">

          </div>
          <div class="action-btn-wrapper">
            <div class="uk-child-width-1-2" uk-grid>
              <div>
                <button class="confirm" @click="saveProfile">儲存變更</button>
              </div>
              <div>
                <button class="cancel" @click="back">取消</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal v-if="showAlert" :msg="errorMsg" :isError="alertTypeIsError" @close="showAlert=false"></modal>
  </div>
</template>

<script>
/* eslint-disable */
import axios from 'axios';
import modal from './modal';

const getCookie = (name) => {
  var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
  if (arr = document.cookie.match(reg))
    return (arr[2]);
  else
    return null;
}

export default {
  name: 'AppProfile',
  props: ['user', 'error', 'success', 'fb_connect', 'g_connect', 'login_types'],
  data() {
    return {
      username: "",
      email: "",
      phone_num: "",
      profileError: {
        username: false,
        email: false,
        phone_num: false
      },
      loading: false,
      errorMsg: '',
      showAlert: false,
      alertTypeIsError: true
    }
  },
  computed: {

  },
  components: { modal },
  methods: {
    back() {
      let result = localStorage.getItem('saveProfile')
      if (result) {
        localStorage.removeItem('saveProfile')
        history.go(-2)
      } else {
        history.go(-1)
      }
    },
    onResetPassword() {
      this.loading = true
      axios({
        method: 'post',
        url: '/api/send-reset-link',
        headers: {
          'X-CSRFToken': getCookie('csrftoken')
        },
        data: {
          email: this.user.email,
        }
      })
      .then((res) => {
        this.loading = false
        this.errorMsg = '密碼重設信已寄出'
        this.alertTypeIsError = false
        this.showAlert = true
      }).catch((err) => {
        this.loading = false
      })
    },
    saveProfile() {
      localStorage.setItem('saveProfile', 'save')
      if (this.username.length === 0) {
        this.profileError.username = true
      } else {
        this.profileError.username = false
      }
      let emailRule = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/
      if (this.email.search(emailRule)!== -1) {
        this.profileError.email = false
      } else {
        this.profileError.email = true
      }
      if (this.phone_num.length > 20) {
        this.profileError.phone_num = true
      } else {
        this.profileError.phone_num = false
      }
      if (
        this.profileError.username ||
        this.profileError.email ||
        this.profileError.phone_num
      ) {
        return
      } else {
        const form = document.createElement('form')
        form.setAttribute('method', 'post')
        form.setAttribute('action', `/profile`)
        let keys = [
          "username",
          "email",
          "phone_num"
        ]
        keys.forEach((key) => {
          if (this[key]) {
            let hiddenField = document.createElement('input')
            hiddenField.setAttribute('type', 'hidden')
            hiddenField.setAttribute('name', key)
            hiddenField.setAttribute('value', this[key])
            form.appendChild(hiddenField)
          }
        })
        let hiddenField = document.createElement('input')
        hiddenField.setAttribute('type', 'hidden')
        hiddenField.setAttribute('name', 'csrfmiddlewaretoken')
        hiddenField.setAttribute('value', getCookie('csrftoken'))
        form.appendChild(hiddenField)
        document.body.appendChild(form)
        form.submit()
        document.body.removeChild(form)
      }
    }
  },
  created() {
    if (this.user.username) {
      this.username = this.user.username
    }
    if (this.user.email) {
      this.email = this.user.email
    }
    if (this.user.phone_num) {
      this.phone_num = this.user.phone_num
    }
    if (this.error) {
      this.alertTypeIsError = true
      this.errorMsg = this.error
      this.showAlert = true
    }
    if (this.success == "true") {
      this.alertTypeIsError = false
      this.errorMsg = '修改成功'
      this.showAlert = true
    }
  }
}
</script>

<style scoped lang="scss">

  /* local styles */
  span.social-connected{
    color: #6a6a6a;
  }

</style>



